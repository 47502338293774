<template>
  <div class="m_lives_main">
    <header>
      <img class="banner" src="@/assets/lives/wbsc-m.jpg" />
      <div class="toolbar">
        <div class="title">{{ activeCat ? cats[activeCat].Name : $t('lives.all') }}</div>
        <div class="cat_select" :class="{ options: catOptionsShow }" @click="showCatOptions()">
          <div class="cat">
            <img class="icon" :src="require(`@/assets/lives/cats/${activeCat}.svg`)" />
            <span class="name">{{ activeCat ? cats[activeCat].Name : $t('lives.all') }}</span>
          </div>
        </div>
      </div>
      <section
        v-if="catOptionsShow"
        ref="cat_options_bg"
        class="cat_options_bg"
        @click="hideCatOptions($event)"
      >
        <div class="cat_options">
          <div
            :key="0"
            class="cat_option"
            :class="{ active: 0 === activeCat }"
            @click="mySetActiveCat(0)"
          >
            <img class="icon" :src="require(`@/assets/lives/cats/0.svg`)" />
            <span class="name">{{ $t('lives.all') }}</span>
          </div>
          <div
            v-for="cat in activeCats"
            :key="cat"
            class="cat_option"
            :class="{ active: cat === activeCat }"
            @click="mySetActiveCat(cat)"
          >
            <img class="icon" :src="require(`@/assets/lives/cats/${cat}.svg`)" />
            <span class="name">{{ cats[cat].Name }}</span>
          </div>
        </div>
      </section>
      <div class="logo_block">
        <img
          v-if="siteLabel"
          class="home_button"
          src="@/assets/lives/home.png"
          @click="openHome()"
        />
        <img v-else class="super_button" src="@/assets/lives/super.png" @click="openHome()" />
      </div>
    </header>
    <main>
      <ul>
        <li v-for="live in activeLives" :key="live.EvtID">
          <div class="catleague">
            <span
              class="icon"
              :style="{
                mask: 'url(' + require(`@/assets/lives/cats/${live.CatID}.svg`) + ')',
              }"
            />
            <span class="cat">{{ live.CatName }}</span>
            <span class="league">{{ live.LeagueName }}</span>
          </div>
          <div class="status" :class="{ now: live.IsRun }">{{
            live.IsRun ? $t('lives.now') : $t('lives.yet')
          }}</div>
          <div class="time">{{ live.ScheduleTimeStr.substr(0, 16) }}</div>
          <div class="team home">
            <span class="name">{{ live.HomeTeamStr }}</span>
            <img
              v-if="live.HomeImage"
              class="logo"
              :src="`/timage/${live.HomeImage}`"
              onerror="javascript:this.src='/img/no.png'"
            />
          </div>
          <img class="vs" src="@/assets/lives/vs.png" />
          <div class="team away">
            <img
              v-if="live.AwayImage"
              class="logo"
              :src="`/timage/${live.AwayImage}`"
              onerror="javascript:this.src='/img/no.png'"
            />
            <span class="name">{{ live.AwayTeamStr }}</span>
          </div>
          <div class="actions">
            <div class="bet" @click="openTable(live)">{{ $t('lives.bet') }}</div>
            <div v-if="live.IsRun" class="view" @click="showLive(live)">{{ $t('lives.view') }}</div>
          </div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    data() {
      return {
        catOptionsShow: false,
      };
    },
    computed: {
      ...mapState(['siteLabel']),
      ...mapState('Lives', ['cats', 'activeCats', 'activeCat']),
      ...mapGetters('Lives', ['activeLives']),
    },
    methods: {
      ...mapMutations('Lives', ['setActiveCat', 'showLive']),
      ...mapActions('Lives', ['openHome', 'openTable']),
      showCatOptions() {
        this.catOptionsShow = true;
      },
      hideCatOptions(event) {
        if (event.target === this.$refs.cat_options_bg) {
          this.catOptionsShow = false;
        }
      },
      mySetActiveCat(cat) {
        this.setActiveCat(cat);
        this.catOptionsShow = false;
      },
    },
  };
</script>

<style lang="scss">
  .m_lives_main {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    color: #868686;
    font-size: 13px;

    > header {
      position: relative;
      z-index: 10;

      > .banner {
        width: 100%;
      }

      > .toolbar {
        position: relative;
        height: 40px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;

        > .title {
          position: absolute;
          left: 8vw;
          top: 50%;
          transform: translateY(-50%);
          color: #00a2ad;
          font-size: 18px;
          font-weight: bold;
        }

        > .cat_select {
          position: absolute;
          width: 120px;
          height: 32px;
          right: 4vw;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 8px;
          font-size: 16px;
          cursor: pointer;

          > .cat {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 4px;
            width: 100%;
            height: 100%;
            padding-left: 12px;
            background-color: #00a8bf;
            border-radius: 8px;
            color: #fff;

            > .icon {
              width: 16px;
            }
          }

          &:after {
            position: absolute;
            width: 12px;
            height: 8px;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            transition: transform 0.4s ease-in-out 0.4s;
            content: '';
          }

          &.options {
            &:after {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
        }
      }

      > .cat_options_bg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);

        > .cat_options {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 36px;
          grid-column-gap: 8px;
          grid-row-gap: 8px;
          position: absolute;
          width: 80%;
          max-width: 480px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 16px;
          border: 1px solid #bbb;
          border-radius: 8px;
          box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.32);
          background-color: #eee;

          > .cat_option {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border-radius: 8px;
            box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.32);
            background-color: #00a8bf;
            color: #eee;
            font-size: 16px;
            cursor: pointer;

            > .icon {
              width: 20px;
            }

            &.active {
              background-color: #0082ab;
              color: #fff;
              font-weight: bold;
            }

            &:hover {
              background-color: #0082ab;
              color: #fff;
            }
          }
        }
      }

      > .logo_block {
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.72);

        > .home_button {
          position: absolute;
          height: 14px;
          left: 4%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          filter: brightness(0.9);

          &:hover {
            filter: brightness(1);
          }
        }

        > .super_button {
          position: absolute;
          height: 12px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          filter: brightness(0.9);

          &:hover {
            filter: brightness(1);
          }
        }
      }
    }

    > main {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      padding: 16px;
      background-color: #e3ebee;

      > ul {
        > li {
          position: relative;
          height: 160px;
          margin-bottom: 8px;
          border-radius: 8px;
          box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.12);
          background-color: #fff;

          > .catleague {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: absolute;
            left: 16px;
            top: 24px;
            z-index: 1;
            transform: translateY(-50%);

            > .icon {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 4px;
              background-color: #0082ab;
            }

            > .cat {
              display: inline-block;
              font-weight: bold;
            }

            > .league {
              display: inline-block;

              &::before {
                margin-left: 4px;
                margin-right: 4px;
                content: '-';
              }
            }
          }

          > .status {
            position: absolute;
            right: 16px;
            top: 24px;
            transform: translateY(-50%);
            padding: 2px 8px 4px;
            border-radius: 16px;
            background-color: #d0e5e8;
            color: #657274;

            &.now {
              background-color: #75efbc;
              font-weight: bold;

              &::before {
                display: inline-block;
                position: relative;
                width: 14px;
                height: 14px;
                top: 2px;
                margin-right: 4px;
                background-image: url('~@/assets/lives/live.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                content: '';
                animation: now 1s ease-in-out 0s infinite alternate;
              }
            }
          }

          > .time {
            position: absolute;
            left: 16px;
            right: 16px;
            top: 44px;
            padding-top: 4px;
            padding-bottom: 4px;
            border-radius: 8px;
            background-color: #e2e2e2;
            color: #657274;
            font-size: 14px;
            text-align: center;
          }

          > .team {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: absolute;
            top: 94px;
            transform: translateY(-50%);
            font-size: 14px;
            font-weight: bold;
            word-break: keep-all;

            > .logo {
              width: 30px;
            }

            &.home {
              right: calc(50% + 24px);

              > .name {
                text-align: right;
              }
            }

            &.away {
              left: calc(50% + 24px);
            }
          }

          > .vs {
            position: absolute;
            width: 30px;
            left: 50%;
            top: 94px;
            transform: translate(-50%, -50%);
          }

          > .actions {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: absolute;
            left: 50%;
            bottom: 8px;
            transform: translateX(-50%);

            > .bet {
              padding: 4px 15px 6px;
              border: 1px solid #00a2ad;
              border-radius: 8px;
              color: #00a2ad;
              font-weight: bold;
              white-space: nowrap;
              cursor: pointer;

              &:hover {
                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.24);
              }
            }

            > .view {
              margin-left: 8px;
              padding: 4px 15px 6px;
              border: 1px solid #00a8bf;
              border-radius: 8px;
              background-color: #00a8bf;
              color: #fff;
              font-weight: bold;
              white-space: nowrap;
              cursor: pointer;

              &:hover {
                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.24);
              }
            }
          }
        }
      }
    }

    @keyframes now {
      100% {
        transform: scale(1.4);
      }
    }
  }
</style>
